const initialState = {
    data: {},
    isheader: true
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
		
    case "SHOW_HEADER":
        return { ...state, loading: true };

    case "HIDE_HEADER":
        return { ...state, loading: false };

    default:
        return state;
    }
};


export default headerReducer;

