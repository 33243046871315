const initialState = {
	data: {},
	loading: true,
	formLoader: false,
	search : null,
	cs_search : null,
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
		case "SHOW_LOADER":
			return { ...state, loading: true };

		case "HIDE_LOADER":
			return { ...state, loading: false };

		case "SHOW_FORM_LOADER":
			return { ...state, formLoader: true };

		case "HIDE_FORM_LOADER":
			return { ...state, formLoader: false };

		case "CALL_SEARCH":
			return { ...state, search: action.payload };

		case "CALL_CASESTUDY_SEARCH":
			return { ...state, cs_search: action.payload };

		default:
			return state;
    }
};


export default loaderReducer;

