import { combineReducers } from "redux";

import loaderReducer from "./loaderReducer";
import headerReducer from "./headerReducer";
import sliderReducer from "./sliderReducer";

export default combineReducers({
    loaderReducer,
    headerReducer,
    sliderReducer
});