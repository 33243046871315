const initialState = {
  startupSlugs: [],
  sliderData: {
    images: [],
    title: ''
  },
  subscribeModalDetails: {},
  commonData: {}
};

const sliderReducer = (state = initialState, action) => {
  switch (action.type) {
  case "SET_STARTUP_SLUGS":
    return { ...state, startupSlugs: action.payload };
  case "SET_SLIDER_DATA":
    return { ...state, sliderData: action.payload };
  case "SET_SUBSCRIBE_MODAL_DETAILS":
    if (action.payload) {
      return { ...state, subscribeModalDetails: action.payload };
    }
    return { ...state, subscribeModalDetails: {}};
  case "SET_COMMON_DATA":
    return { ...state, commonData: action.payload || {}};
  default:
    return state;
  }
};


export default sliderReducer;

